import { useState, useContext } from "react";

import Screen from "./Components/Screen";
import Header from "./Components/Header";
import Menu from "./Components/Menu";
import Line from "./Components/Line";
import MobilePortfolio from "./Components/MobilePortfolio";
import WebPortfolio from "./Components/WebPortfolio";

import mobile from "./assets/img/mobile.png";
import web from "./assets/img/web.png";

function App() {
  const [webImgClass, setWebImgClass] = useState("webimg");
  const [mobileImgClass, setMobileImgClass] = useState("mobileimg");

  const handleMobileClick = () => {
    setMobileSelected(true);
    setWebSelected(false);
    setMobileImgClass("mobileimgselected");
    setWebImgClass("webimg");
  };
  const handleWebClick = () => {
    setWebSelected(true);
    setMobileSelected(false);
    setMobileImgClass("mobileimg");
    setWebImgClass("webimgselected");
  };
  const [mobileSelected, setMobileSelected] = useState(false);
  const [webSelected, setWebSelected] = useState(false);
  return (
    <>
      <Screen>
        <Header />
        <div className="maincontainer">
          <img
            className={mobileImgClass}
            src={mobile}
            alt="Mobile Development"
            onClick={() => handleMobileClick()}
          />{" "}
          <img
            className={webImgClass}
            src={web}
            alt="Web Development"
            onClick={() => handleWebClick()}
          />
        </div>
        {mobileSelected && <MobilePortfolio />}
        {webSelected && <WebPortfolio />}
      </Screen>
    </>
  );
}

export default App;
