import React from "react";

import "../Style/Styles.scss";

import myGif from "../assets/watchtonight.gif";

function MobilePortfolio() {
  return (
    <div className="portfoliocontainer">
      <img className="mobileportfoliogif" src={myGif} alt="my-gif" />
    </div>
  );
}

export default MobilePortfolio;
