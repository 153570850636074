import React from "react";

import "../Style/Styles.scss";
import breakwave from "../assets/img/breakwave.png";
import meadestreet from "../assets/img/meadestreet.png";
import weisds from "../assets/img/weisds.png";
import moneyspot from "../assets/img/moneyspot.png";

function WebPortfolio() {
  return (
    <div className="portfoliocontainer">
      <div className="webcard">
        <a
          rel="noreferrer"
          target="_blank"
          href="https://breakwaverecords-8bb6f.web.app/"
        >
          <img className="webcardimg" src={breakwave} alt="Breakwave Records" />
        </a>
      </div>
      <div className="webcard">
        <a rel="noreferrer" target="_blank" href="https://weisds.com">
          <img className="webcardimg" src={weisds} alt="Wei Wei Drapery" />
        </a>
      </div>
      <div className="webcard">
        <a
          rel="noreferrer"
          target="_blank"
          href="https://meade-street-music.vercel.app"
        >
          <img
            className="webcardimg"
            src={meadestreet}
            alt="Meade Street Music"
          />
        </a>
      </div>
      <div className="webcard">
        <a
          rel="noreferrer"
          target="_blank"
          href="https://moneyspot-3c74a.web.app/"
        >
          <img className="webcardimg" src={moneyspot} alt="Money Spot" />
        </a>
      </div>
    </div>
  );
}

export default WebPortfolio;
