import React from "react";

import "../Style/Styles.scss";

function Line() {
  return (
    <div className="line">
      -----------()--------------------+---------------------[]--------------------//-------------
    </div>
  );
}

export default Line;
