import "../Style/Styles.scss";

import logo from "../assets/img/lslogo.png";

import mobile from "../assets/img/mobile.png";
import web from "../assets/img/web.png";

function Header() {
  return (
    <>
      <div className="headercontainer">
        <img
          alt="light studios cutting edge web and app development"
          className="logoimg"
          src={logo}
        ></img>
        {/* <div className="headertext">
          cutting edge
          <strong> web/app development</strong>
        </div> */}
      </div>
    </>
  );
}

export default Header;
